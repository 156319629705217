.copyright-copyright {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.copyright-max-width {
  align-items: stretch;
  justify-content: center;
}
.copyright-text {
  color: var(--dl-color-grays-gray100);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
}
.copyright-root-class-name {
  align-self: center;
}
@media(max-width: 991px) {
  .copyright-max-width {
    flex-direction: column;
  }
  .copyright-text {
    text-align: center;
  }
}
