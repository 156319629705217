.slide-slide {
  align-items: stretch;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-backgrounds-green);
}
.slide-max-width {
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.slide-left-side {
  width: 50%;
  display: flex;
  align-items: stretch;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.slide-decorations-container {
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.slide-dots {
  width: 126px;
  height: 126px;
  margin-top: 21px;
  object-fit: cover;
  margin-right: 21px;
}
.slide-squares-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.slide-light-green {
  flex: 0 0 auto;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-backgrounds-lightgreen);
}
.slide-orange {
  flex: 0 0 auto;
  width: 108px;
  height: 108px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-backgrounds-primary);
}
.slide-image-container {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}
.slide-image {
  width: 100%;
  height: 485px;
  object-fit: cover;
}
.slide-right-side {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.slide-testimonial {
  color: var(--dl-color-grays-white100);
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 42px;
}
.slide-author {
  color: var(--dl-color-grays-white100);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}
.slide-role {
  color: var(--dl-color-grays-white60);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.slide-root-class-name {
  background-color: var(--dl-color-backgrounds-purple);
}
.slide-root-class-name1 {
  background-color: var(--dl-color-backgrounds-green);
}
.slide-root-class-name2 {
  background-color: var(--dl-color-backgrounds-green);
}
@media(max-width: 991px) {
  .slide-left-side {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 767px) {
  .slide-max-width {
    flex-direction: column;
  }
  .slide-left-side {
    width: 100%;
    padding-right: 0px;
  }
  .slide-image {
    max-width: 700px;
  }
  .slide-right-side {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .slide-dots {
    width: 60px;
    height: 60px;
  }
  .slide-light-green {
    width: 36px;
    height: 36px;
  }
  .slide-orange {
    width: 75px;
    height: 75px;
  }
}
