.home-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-backgrounds-gray);
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-max-width {
  align-items: stretch;
}
.home-content-container {
  flex: 1;
  display: flex;
  min-width: 50%;
  align-items: flex-start;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text {
  width: 100%;
  margin-top: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text03 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text06 {
  color: #794204;
}
.home-image-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image {
  width: 70%;
  max-width: 589px;
  align-self: center;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  max-height: 700px;
  object-fit: cover;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius35);
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.home-section-one {
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-max-width1 {
  flex-direction: column;
}
.home-text10 {
  font-size: 42px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text11 {
  color: var(--dl-color-grays-gray100);
  align-self: center;
  text-align: center;
  line-height: 2;
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-max-width2 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-image-container1 {
  flex: 1;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.home-image1 {
  width: 100%;
  max-width: 560px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
}
.home-content-container1 {
  flex: 1;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text12 {
  padding-left: var(--dl-space-space-twounits);
  margin-bottom: 24px;
  padding-right: var(--dl-space-space-twounits);
}
.home-text14 {
  color: var(--dl-color-backgrounds-primary);
}
.home-max-width3 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-content-container2 {
  flex: 1;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text15 {
  margin-bottom: 24px;
}
.home-text17 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text18 {
  color: var(--dl-color-grays-gray100);
}
.home-image-container2 {
  flex: 1;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.home-image2 {
  width: 100%;
  max-width: 560px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
}
.home-max-width4 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-image-container3 {
  flex: 1;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.home-image3 {
  width: 100%;
  max-width: 560px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  border-radius: 15px;
}
.home-image4 {
  width: 100%;
  max-width: 560px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
  border-radius: 15px;
}
.home-content-container3 {
  flex: 1;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text22 {
  margin-bottom: 24px;
}
.home-text24 {
  color: var(--dl-color-backgrounds-primary);
}
.home-max-width5 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-content-container4 {
  flex: 1;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text25 {
  margin-bottom: 24px;
}
.home-text27 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text28 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text29 {
  color: var(--dl-color-grays-gray100);
}
.home-image-container4 {
  flex: 1;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.home-image5 {
  width: 100%;
  max-width: 560px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  border-radius: 15px;
}
.home-section-five {
  padding-bottom: 0px;
}
.home-max-width6 {
  flex-direction: column;
}
.home-text30 {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: Lexend;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}
.home-text31 {
  color: #212121;
}
.home-text32 {
  color: var(--dl-color-backgrounds-primary);
}
.home-slider {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  transition: 0.5s;
  align-items: stretch;
  flex-direction: row;
}
.home-max-width7 {
  flex-direction: column;
  justify-content: center;
}
.home-heading-container {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text33 {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text35 {
  color: var(--dl-color-backgrounds-primary);
}
.home-contact-me {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link {
  color: var(--dl-color-grays-white100);
  width: 170px;
  text-align: center;
  text-decoration: none;
  background-color: var(--dl-color-backgrounds-green);
}
@media(max-width: 991px) {
  .home-max-width {
    flex-direction: column;
  }
  .home-content-container {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text {
    width: 100%;
  }
  .home-image1 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-content-container1 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text12 {
    width: 100%;
  }
  .home-content-container2 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text15 {
    width: 100%;
  }
  .home-image2 {
    max-width: 100%;
  }
  .home-image3 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-image4 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-content-container3 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text22 {
    width: 100%;
  }
  .home-content-container4 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text25 {
    width: 100%;
  }
  .home-image5 {
    max-width: 100%;
    margin-left: 0px;
  }
}
@media(max-width: 767px) {
  .home-text10 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-cards-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-image-container1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image1 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-content-container1 {
    max-width: 100%;
  }
  .home-max-width3 {
    flex-direction: column-reverse;
  }
  .home-content-container2 {
    max-width: 100%;
  }
  .home-image-container2 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image2 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-max-width4 {
    flex-direction: column;
  }
  .home-image-container3 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image3 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-image4 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-content-container3 {
    max-width: 100%;
  }
  .home-max-width5 {
    flex-direction: column-reverse;
  }
  .home-content-container4 {
    max-width: 100%;
  }
  .home-image-container4 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image5 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text30 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text33 {
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .home-text {
    text-align: center;
  }
  .home-cards-container {
    justify-content: center;
  }
  .home-image-container2 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-image-container4 {
    margin-left: 0px;
  }
  .home-text33 {
    font-size: x-large;
  }
}
